<template>
    <div>
    <div>
    <b> <font face=angsananew size=5 color=blue>
        &emsp;Standard Software Application policy
        </font><br><br></b>
    </div>
<div>
    <b><font face=angsananew size=4 >&emsp;1.	Overview</font></b><br>
</div>
<div>
    <font face=angsananew>
    &emsp;A standardized software policy offers better supportability, a more consistent operating experience for <br> 
    &emsp;users and is one component used to help protect the security of [LEP] resources.  [Insert Appropriate Department] <br> 
    &emsp;is tasked with ensuring that only approved applications are used on computing devices within the organization.
    </font><br><br>
</div>
<div>
    <b><font face=angsananew size=4 >&emsp;2.	Purpose</font></b><br>
</div>
<div>
    <font face=angsananew>
    &emsp;This policy is designed to protect [LEP] by requiring all staff to only run software application programs  <br> 
    &emsp;deemed safe and standard by the [LEP] [Insert Appropriate Department].  This helps to ensure  <br> 
    &emsp;applications in use by staff meet [LEP] security, functional, and support criteria.  
    </font><br><br>
</div>
<div>
    <b><font face=angsananew size=4 >&emsp;3.	Scope</font></b><br>
</div>
<div>
    <font face=angsananew>
    &emsp;This policy applies to all [LEP] staff.
    </font><br><br>
</div>
<div>
    <b><font face=angsananew size=4 >&emsp;4.	Policy</font></b><br>
</div>
    <div>
        &emsp;The [Insert Appropriate Department] shall prepare and maintain a list of software applications that are <br>
        &emsp;approved for general use by staff.  Programs that appear on the approved list of software applications <br>
        &emsp;shall include general office productivity products (e.g. word processing, spreadsheets, presentations) as <br>
        &emsp;well as utility and management programs specific to a department or function <br> 
        &emsp;(e.g. anti-malware, inventory, help desk, custom applications, on-demand apps and software, etc.). <br><br>
        &emsp;Only approved software applications are allowed to access [LEP] networks and information resources. <br>
        &emsp;Under no circumstances shall staff use programs that are not on the approved list of software applications. <br>
        &emsp;Staff members who introduce a security issue by installing and running an unapproved program <br>
        &emsp;risk disciplinary action. <br><br>
        &emsp;Staff are encouraged to use and operate programs that appear on the approved list of software <br>
        &emsp;applications wherever possible.  However, there are always exceptions based on special use cases. <br> 
        &emsp;Should a staff member need access to a piece of software that does not appear on the approved list for <br>
        &emsp;their work, they must submit a justification request to the [LEP] Helpdesk to evaluate the application and request. <br> 
        &emsp;This process applies to licensed applications, shareware, freeware, trial programs, and all other software installed <br>
        &emsp;on the user’s business computing asset(s).  The [LEP] Helpdesk regularly evaluates special requests and also updates <br> 
        &emsp;the approved list of software applications on an as needed basis. <br><br>
        &emsp;Special exceptions may be made to this policy for specific employees depending on the required job <br>
        &emsp;function and the skills of the employee.  Possible reasons for a granted exception include: <br><br>
        &emsp;&emsp;•	Staff who require specialized software to test new applications on development, test and production networks<br><br>
        &emsp;&emsp;•	A programmer that must execute non-standard applications in order to test the software<br><br>
        &emsp;&emsp;•	Network administrators who install and test software as a part of their regular job duties<br><br>
        &emsp;&emsp;•	Users or contractors providing specialized service functions within the organization<br><br>
        &emsp;Exceptions are handled on a case-by-case basis by the [Insert Appropriate Role] or their designee and documented. <br><br>
    </div>
<div>
    <b><font face=angsananew size=4 >&emsp;5.	Audit Controls and Management</font></b><br>
</div>
<div>
    <font face=angsananew>
    &emsp;On-demand documented procedures and evidence of practice should be in place for this operational <br> 
    &emsp;policy as part of the [LEP] internal application development and release methodology.  Examples of audit   <br> 
    &emsp;based evidence include: <br><br> 
    &emsp;&emsp;•	Samples of the policy implementation in archival documentation<br><br>
    &emsp;&emsp;•	Regular documented and published updates of approved [LEP] software<br><br>
    &emsp;&emsp;• Documentation of approved and disallowed software requests<br><br>
    &emsp;&emsp;•	Documentation of at least an annual review of software and standards for the [LEP]
    </font><br><br>
</div>
<div>
    <b><font face=angsananew size=4 >&emsp;6.	Enforcement</font></b><br>
</div>
<div>
    <font face=angsananew>
    &emsp;Staff members found in policy violation may be subject to disciplinary action, up to and including termination.
    </font><br><br>
</div>
<div>
    <b><font face=angsananew size=4 >&emsp;7.   safety practices</font></b><br>
</div>
<div>
    <font face=angsananew>
    &emsp;• Data is encrypted as it is sent.
  The system sends data over a secure connection.<br/>
    &emsp;• You can request deletion.
  The app developer has a way for you to request data deletion.<a href="/request-account">Request</a>
    </font><br><br>
</div>
<div>
    <b><font face=angsananew size=4 >&emsp;8.  Contact Us and Support</font></b><br>
</div>
<div>
    <font face=angsananew>
    &emsp;• Can contact to request for support by <a href="/support">Support</a>
    </font><br><br>
</div>
<div>
    <b><font face=angsananew size=4 >&emsp;9.	Distribution</font></b><br>
</div>
<div>
    <font face=angsananew>
    &emsp;This policy is to be distributed to all [LEP] staff.
    </font><br><br>
</div>
<div>
    <b><font face=angsananew size=4 >&emsp;10.	Policy Version History</font></b><br>
</div>
<div>
    <font face=angsananew>
    <table border="1" width="60%">
  <tr style="background-color:#D3D0D0">
    <th width="25%">Version</th>
    <th width="25%">Date</th>
    <th width="35%">Description</th>
    <th width="25%">Approved By</th>
  </tr>
  <tr>
    <td width="25%">1.0</td>
    <td width="25%">9/15/2016</td>
    <td width="35%">Initial Policy Drafted</td>
    <td width="25%">.</td>
  </tr>
  <tr>
      <td width="25%"> </td>
    <td width="25%"> </td>
    <td width="35%"> </td>
    <td width="25%">.</td>
  </tr>
   <tr>
      <td width="25%"> </td>
    <td width="25%"> </td>
    <td width="35%"> </td>
    <td width="25%">.</td>
  </tr>
  </table>
    </font><br><br>
</div>
    </div>
</template>
<style>
table, th, td {
  border: 1px solid black;
}
</style>
<script>
export default {
    
}
</script>